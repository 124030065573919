package widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew

import csstype.*
import utils.types.CssStyle

val nextStepContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 22.px
}

val nextStepTextContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 6.px
}

val nextStepHeaderContainer: CssStyle = {
    display = Display.flex
    gap = 8.px
    alignItems = AlignItems.center
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    gap = 16.px
}

val shareButton: CssStyle = {
    color = Color("white")
    backgroundColor = Color("#597EF7")
    borderRadius = 8.px
    border = None.none
    fontWeight = FontWeight.bold
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Lexend\", system-ui")
    width = 250.px
    height = 51.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 6.px
    hover {
        backgroundColor = Color("#3E62D9")
        color = important(Color("white"))
    }
}

val newTaskButton: CssStyle = {
    color = Color("#597EF7")
    backgroundColor = Color("white")
    borderRadius = 8.px
    border = Border(2.px, LineStyle.solid, Color("#E3E6EB"))
    fontWeight = FontWeight.bold
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Lexend\", system-ui")
    width = 250.px
    height = 51.px
    display = Display.flex
    alignItems = AlignItems.center
    textAlign = TextAlign.center
    flexWrap = FlexWrap.wrap // Разрешаем перенос
    whiteSpace = WhiteSpace.normal // Автоматический перенос текста
    justifyContent = JustifyContent.center
    gap = 6.px
    hover {
        backgroundColor = Color("white")
        color = important(Color("#2F54EB"))
        border = Border(2.px, LineStyle.solid, Color("#E3E6EB"))
    }
}
