package online.interactiver.common.payment

import kotlinx.serialization.Serializable

@Serializable
data class TariffCosts(
    val monthCost: Int,
    val yearCost: Int,
    val partnersCost: Int
)
