package widgets.StudentDuoContent.ui.TopicTextArea

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.stretch
    gap = 10.px
    position = Position.relative
}

val labelContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.flexEnd
}

val label: CssStyle = {
    color = Color("#282F3E")
    fontWeight = integer(600)
    fontSize = 15.px
    fontStyle = FontStyle.normal
    fontFamily = string("\"Lexend\", system-ui")
}

val header: CssStyle = {
    color = Color("#282F3E")
    fontWeight = integer(700)
    fontSize = 15.px
    fontStyle = FontStyle.normal
}

val textArea: CssStyle = {
    height = important(200.px)
    resize = important(None.none)
    fontSize = 18.px
}
