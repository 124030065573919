package pages.googleAuth.ui

import antd.Button
import antd.Option
import antd.Select
import csstype.*
import emotion.react.css
import kotlinx.browser.window
import kotlinx.js.timers.setTimeout
import online.interactiver.common.enums.ELanguage
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.router.dom.useSearchParams
import react.useState
import shared.components.description.Description
import shared.components.header.Header
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import widgets.StudentDuoContent.ui.EStudentDuoSearchParams
import kotlin.time.Duration.Companion.seconds

external interface RedirectFromEmbeddedBrowserProps : Props {
    var nativeLanguage: String
}

val RedirectFromEmbeddedBrowser = FC<RedirectFromEmbeddedBrowserProps> { props ->
    val (copied, setCopied) = useState(false)
    val (searchParams, setSearchParams) = useSearchParams()
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 20.px
            alignItems = AlignItems.center
            textAlign = TextAlign.center
        }
        Select {
            css {
                alignSelf = AlignSelf.flexEnd
            }
            value = props.nativeLanguage
            onChange = { it, _ ->
                searchParams.set(EStudentDuoSearchParams.INTERFACE_LANGUAGE.value, it)
                setSearchParams(searchParams)
            }
            ELanguage.entries.forEach { language ->
                Option {
                    value = language.text
                    span {
                        +language.icon
                    }
                    +language.text
                }
            }
        }
        div {
            Header {
                text = getHeader1(props.nativeLanguage)
            }
            Description {
                +getDesc1(props.nativeLanguage)
            }
        }
        div {
            Header {
                text = getHeader2(props.nativeLanguage)
            }
            Description {
                +getDesc2(props.nativeLanguage)
            }
        }
        Button {
            css(generateButton)
            onClick = {
                openInDefaultBrowser(window.location.href)
            }
            +defaultBrowserButton(props.nativeLanguage)
        }
        Button {
            css(newTaskButton)
            onClick = {
                window.navigator.clipboard.writeText(window.location.href)
                setCopied(true)
                setTimeout(3.seconds) {
                    setCopied(false)
                }
            }
            +if (copied) copiedLinkButton(props.nativeLanguage) else copyLinkButton(props.nativeLanguage)
        }
        Description {
            +copyLinkDesc(props.nativeLanguage)
        }
    }
}

val getHeader1 = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "⚠️ Этот браузер не поддерживает запись голоса!"
    } else {
        "⚠️ This browser doesn't support voice recording!"
    }
}

val getDesc1 = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "И вы не сможете тренировать произношение"
    } else {
        "And you won't be able to train pronunciation"
    }
}

val getHeader2 = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "⚡ Чтобы прокачать язык в диалогах, пожалуйста, перейдите в Chrome, Safari"
    } else {
        "⚡ To boost your language in dialogs, please, go to Chrome, Safari"
    }
}

val getDesc2 = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "или другой распространенный браузер"
    } else {
        "or another widespread browser"
    }
}

val defaultBrowserButton = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "В браузер по умолчанию"
    } else {
        "To the default browser"
    }
}

val copyLinkButton = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Копировать ссылку"
    } else {
        "Copy url"
    }
}

val copiedLinkButton = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Ссылка скопирована"
    } else {
        "Url copied"
    }
}

val copyLinkDesc = { nativeLanguage: String ->
    if (nativeLanguage == ELanguage.RUSSIAN.text) {
        "Откройте ссылку в обычном браузере (сейчас вы мессенджере или другом приложении)"
    } else {
        "Open the link in a regular browser (now you're in a messanger or another app)\n"
    }
}