package pages.constructor.ui.components.tools

import csstype.*
import utils.types.CssStyle

val leftPanelWidth = 300

val toolsContainer: CssStyle = {
    position = Position.relative
    backgroundColor = Color("#FFFFFF")
    width = leftPanelWidth.px
    height = 100.pct
    borderRadius = 0.px
}

val panel: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 20.px
    lineHeight = 26.px
    backgroundColor = Color("#FFFFFF")
    borderRadius = 0.px
    alignItems = AlignItems.center
    borderBottom = Border(0.px, LineStyle.solid)
}
