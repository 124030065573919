package widgets.AdminPanel.ui

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent

val widget: CssStyle = {
    position = Position.sticky
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 32.px
    top = 44.px
    height = FitContent.fitContent
}

val panel: CssStyle = {
    backgroundColor = Color("#FFFFFF")
    borderRadius = 12.px
    gap = 20.px
    padding = 22.px
    display = Display.flex
    flexDirection = FlexDirection.column
}

val tabs: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 14.px
}

val iconContainer: CssStyle = {
    height = 100.pct
    backgroundColor = Color("#F4F7FF")
    borderRadius = 6.px
    width = 32.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val tab: (Boolean) -> CssStyle = {
    {
        backgroundColor = Color("white")
        display = Display.flex
        gap = 12.px
        alignItems = AlignItems.center
        cursor = Cursor.pointer
        color = Color(if (it) "#2F54EB" else "#344054")
        border = None.none
        boxShadow = None.none
    }
}

val logoContainer: CssStyle = {
    padding = Padding(18.px, 22.px)
    borderRadius = 12.px
    gap = 4.px
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    fontSize = 10.px
    lineHeight = 13.5.px
    color = Color("#5D6676")
    backgroundColor = Color("#FFFFFF")
}

val mobileHeaderContainer: CssStyle = {
    width = 100.pct
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    maxWidth = 960.px
}

val iconButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    border = None.none
    boxShadow = None.none
    padding = 0.px
}

val profile: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    gap = 12.px
    padding = Padding(0.px, 15.px)
    border = None.none
    boxShadow = None.none
    alignItems = AlignItems.flexStart
    height = FitContent.fitContent
}

val userName: CssStyle = {
    margin = 0.px
    color = Color("#757575")
    fontSize = 14.px
    lineHeight = 135.pct
    fontWeight = integer(600)
}

val viewProfile: CssStyle = {
    margin = 0.px
    color = Color("#BDBDBD")
    fontSize = 14.px
    fontWeight = FontWeight.normal
    lineHeight = 135.pct
}

val userNameContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
    gap = 2.px
}

val imageProfile: CssStyle = {
    width = 44.px
    height = 44.px
    minWidth = 44.px
    minHeight = 44.px
    borderRadius = 50.pct
}

val imageReplacement: CssStyle = {
    color = Color("#597ef7")
    backgroundColor = Color("#f4f7ff")
    fontSize = 15.4.px
    lineHeight = 135.pct
    fontWeight = integer(600)
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val upgradeLink: CssStyle = {
    color = Color("white")
    borderRadius = 6.px
    border = None.none
    fontWeight = FontWeight.bold
    lineHeight = 20.8.px
    fontSize = 16.px
    fontFamily = string("\"Lexend\", system-ui")
    height = 44.px
    background = linearGradient(90.deg, Color("#9090fa"), Color("#597EF7"))
    width = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    textDecoration = None.none
    hover {
        background = linearGradient(90.deg, Color("#3E62D9"), Color("#3E62D9"))
        color = important(Color("white"))
    }
}

val upgradeContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 10.px
    width = 80.pct
    margin = Margin(0.px, Auto.auto)
}

val upgradeText: CssStyle = {
    margin = 0.px
    textAlign = TextAlign.center
}
