package entities.interactivePicture.elements.editors.ScoreEditor.ui

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    rowGap = 10.px
    padding = 20.px
    paddingBottom = 0.px
    borderTop = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    alignItems = AlignItems.start
    width = 210.px
    marginBottom = 20.px
    lastChild {
        marginBottom = 48.px
    }
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
}

val button: CssStyle = {
    display = Display.flex
    borderWidth = 0.px
    backgroundColor = Color("#F2F4F7")
    width = 100.pct
    height = 35.px
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#101828")
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    order = integer(0)
    flexGrow = number(0.0)
    borderRadius = 5.px
}

val horizontalContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    flexDirection = FlexDirection.row
}

val select: CssStyle = {
    flex = number(1.0)
}


val longInput: CssStyle = {
    paddingLeft = 4.px
    paddingRight = 4.px
    alignItems = AlignItems.center
    textAlign = TextAlign.center
    borderRadius = 5.px
    marginLeft = 10.px
    width = 50.px
    fontSize = 12.px
}


val shortInput: CssStyle = {
    paddingLeft = 4.px
    paddingRight = 4.px
    alignItems = AlignItems.center
    textAlign = TextAlign.center
    borderRadius = 5.px
    width = 30.px
    fontSize = 11.px
}

val deleteButton: CssStyle = {
    padding = 0.px;
    marginTop = (-10).px
    marginBottom = (-10).px
    fontSize = 11.px
}
