package entities.interactivePicture.elements.editors.textEditor

import csstype.*
import utils.types.CssStyle

val container= {containerFlexDirection: FlexDirection ->
    val bigContainer: CssStyle = {
        paddingBottom = 10.px
        paddingLeft = 20.px
        width = 210.px
        display = Display.flex
        this.flexDirection = containerFlexDirection
        alignItems = AlignItems.center
    }
    bigContainer
}

val header = { widthHeader: Int, fontWeightHeader: Int ->
    val textHeader: CssStyle = {
        fontFamily = string("\"Lexend\", system-ui")
        fontStyle = FontStyle.normal
        fontWeight = integer(fontWeightHeader)
        fontSize = 12.px
        lineHeight = 18.px
        letterSpacing = (-0.002).em
        color = Color("#2E2E2E")
        flex = None.none
        order = integer(0)
        flexGrow = number(0.0)
        marginTop = 6.px
        marginBottom = 6.px
        width = widthHeader.px
        display = Display.flex
        alignItems = AlignItems.center
    }
    textHeader
}

val wrapContainer = { width: Int ->
    val container: CssStyle = {
        display = Display.block
        this.width = width.px
    }
    container

}

val editorTextContainer: CssStyle = {
    height = 24.px

    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 12.px

    color = Color("#333333")

    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween

    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left

    padding = 0.px
    width = 100.pct
}

val getTextArea = { height: Int, width: Int ->
    val textarea: CssStyle = {
        resize = important(None.none)
        padding = Padding(9.5.px, 6.px)
        backgroundColor = Color("#FFFFFF")
        borderRadius = 3.px

        fontFamily = string("\"Lexend\", system-ui")
        fontStyle = FontStyle.normal
        fontWeight = integer(400)
        fontSize = 12.px
        lineHeight = important(14.px)
        color = Color("#2E2E2E")

        this.height = important(height.px)
        this.width = important(width.px)
    }
    textarea
}