package pages.constructor.ui.components.inputImage

import csstype.*
import utils.types.CssStyle

val button: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    gap = 20.px
    whiteSpace = WhiteSpace.normal
    textAlign = TextAlign.left
    height = Auto.auto
    width = 250.px
    paddingTop = 20.px
    paddingBottom = 20.px
    paddingLeft = 20.px
    paddingRight = 20.px
    border = Border(1.px, LineStyle.dashed, Color("#C1C1C1"))
    borderRadius = 3.px
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 14.px
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
}

val inputImageStyle: CssStyle = {
    display = Display.block
    width = 0.px
    height = 0.px
}

val inputImageButtonStyle: CssStyle = {
    alignItems = AlignItems.center
    padding = Padding(5.px, 3.px)
    width = 36.px
}
