package entities.interactivePicture.elements.controls.selectors.ui.editor

import csstype.*
import pages.constructor.ui.constructorBgColor
import utils.inject
import utils.types.CssStyle

val container: CssStyle = {
    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    padding = 20.px
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
}

val wrapContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    width = 210.px
}

val inputContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.spaceBetween
    alignItems = AlignItems.center
    gap = 10.px
    marginTop = 10.px
}

val input: CssStyle = {
    resize = important(None.none)
    padding = Padding(10.px, 2.px, 10.px, 10.px)
    backgroundColor = Color(constructorBgColor)
    borderRadius = 3.px

    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    color = Color("#2E2E2E")

    height = Globals.inherit
}

val removeButton: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center
    padding = 5.px
}

val removeButtonDisabled: CssStyle = {
    inject(removeButton)
    borderWidth = 0.px
}

val saveButtonDisabled: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    justifyContent = JustifyContent.center
    alignItems = AlignItems.center

    padding = Padding(10.px, 15.px, 10.px, 15.px)

    height = important(35.px)

    fontFamily = string("\"Arial\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 14.px
    lineHeight = important(135.pct)
    color = Color("#FFFFFF")

    backgroundColor = Color("#4369EE")
    borderRadius = 5.px

    marginTop = 20.px
    marginBottom = 10.px
}

val saveButton: CssStyle = {
    inject(saveButtonDisabled)
    hover {
        color = important(Color("#FFFFFF"))
    }
}
