package online.interactiver.common.utils

import io.ktor.util.date.*
import kotlin.math.min

fun getRandUID(length: Int): String {
    val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}

fun getRandCode(length: Int): String {
    val allowedChars = ('0'..'9')
    return (1..length)
        .map { allowedChars.random() }
        .joinToString("")
}

fun ULong.toBase(base: UInt = 66u): String {
    val alphabet = ('0'..'9') + ('a'..'z') + ('A'..'Z')
    val safeBase = kotlin.math.min(base, alphabet.size.toUInt())
    var res = ""
    var value = this
    while (value > 0u) {
        res += alphabet[(value % safeBase).toInt()]
        value /= safeBase
    }
    return res
}

fun getTimeMillisInULong() = getTimeMillis().toULong()

fun getTimeMillisInBase(base: UInt = 66u): String {
    return getTimeMillisInULong().toBase(base)
}

fun String.snakeToCamelCase(): String{
    val pattern = "_[a-z0-9]".toRegex()
    return replace(pattern) { it.value.last().uppercase() }
}

fun String.findAllIndexesOfSubstring(subStr: String): List<Int> {
    val indexes = mutableListOf<Int>()
    var lastIndex = 0

    while (lastIndex != -1) {
        lastIndex = this.indexOf(subStr, lastIndex)

        if (lastIndex != -1) {
            indexes.add(lastIndex)
            lastIndex += subStr.length // Move to the end of the current found substring
        }
    }

    return indexes
}

fun String.getName(maxLength: Int) : String {
    return if (this.length <= maxLength || maxLength < 5) {
        this
    } else {
        this.take(maxLength)
    }
}

fun levenshtein(lhs : CharSequence, rhs : CharSequence) : Int {
    if(lhs == rhs) { return 0 }
    if(lhs.isEmpty()) { return rhs.length }
    if(rhs.isEmpty()) { return lhs.length }

    val lhsLength = lhs.length + 1
    val rhsLength = rhs.length + 1

    var cost = Array(lhsLength) { it }
    var newCost = Array(lhsLength) { 0 }

    for (i in 1..rhsLength-1) {
        newCost[0] = i

        for (j in 1..lhsLength-1) {
            val match = if(lhs[j - 1] == rhs[i - 1]) 0 else 1

            val costReplace = cost[j - 1] + match
            val costInsert = cost[j] + 1
            val costDelete = newCost[j - 1] + 1

            newCost[j] = min(min(costInsert, costDelete), costReplace)
        }

        val swap = cost
        cost = newCost
        newCost = swap
    }

    return cost[lhsLength - 1]
}