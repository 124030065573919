package online.interactiver.common.user

import kotlinx.serialization.Serializable

@Serializable
data class UpdateUserRequest(
    val name: String? = null,
    val about: String? = null,
    val nativeLanguage: String? = null,
    val languageToLearn: String? = null,
    val additionalEmail: String? = null,
    val socials: String? = null,
    val autoSoundExercise: Boolean? = null,
    val languageLevel: String? = null,
)