package entities.interactivePicture.elements.gapPuzzles.drags.ui.editor

import csstype.*
import pages.constructor.ui.constructorBgColor
import utils.types.CssStyle

val container: CssStyle = {
    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
    padding = 20.px
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
    marginBottom = 10.px
}

val wrapContainer: CssStyle = {
    display = Display.flex
}

val textarea: CssStyle = {
    resize = important(None.none)
    padding = Padding(10.px, 15.px)
    backgroundColor = Color(constructorBgColor)
    borderRadius = 3.px

    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 12.px
    lineHeight = important(14.px)
    color = Color("#2E2E2E")

    height = important(100.px)
}
