package entities.interactivePicture.background.ui.tools

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center

    // Пришлось подгонять падинги, тк. остальные колапсы не совпадают с Figma

    paddingTop = 14.px
    paddingBottom = 14.px
    paddingLeft = 16.px
    paddingRight = 11.px

    borderBottom = Border(1.px, LineStyle.solid, Color("#D7D7D7"))
}

val image: CssStyle = {
    width = 100.pct
}


val button: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    padding = Padding(5.px, 3.px)
    gap = 10.px
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    color = Color("#2E2E2E")
    fontSize = 20.px
    flex = Flex.maxContent
}

