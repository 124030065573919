package pages.languageAuto.ui

import csstype.*
import entities.viewport.EAvailableBreakpoints
import utils.types.CssStyle

val page: (Int) -> CssStyle = { windowWidth ->
    {
        display = Display.flex
        if (windowWidth < EAvailableBreakpoints.MOBILE.width) {
            alignItems = AlignItems.center
            flexDirection = FlexDirection.column
        } else {
            justifyContent = JustifyContent.center
        }
        backgroundColor = Color("#F4F7FF")
        padding = Padding(44.px, 44.px)
        gap = 32.px
        minHeight = 100.vh
        fontFamily = string("\"Lexend\", system-ui")
        boxSizing = BoxSizing.borderBox
    }
}

val exercise: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    flexDirection = FlexDirection.column
    backgroundColor = Color("#F4F7FF")
    gap = 40.px
    minHeight = 120.vh
}