package entities.interactivePicture.elements.shared.tools

import csstype.*
import pages.constructor.ui.constructorBgColor
import utils.types.CssStyle

val buttonsGroup: CssStyle = {
    display = Display.grid
    gridTemplateColumns = repeat(2, minmax(0.px, 1.fr))
    gap = 10.px
    margin = Margin(30.px, 4.px)
    justifyItems = JustifyItems.center
}

val button: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceBetween
    padding = Padding(10.px, 15.px)
    gap = 10.px

    width = 125.px
    height = 60.px
    backgroundColor = Color(constructorBgColor)
    borderWidth = 0.px
    borderRadius = 3.px

    whiteSpace = WhiteSpace.normal
}

val buttonText: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(500)
    fontSize = 14.px
    lineHeight = 17.px
    color = Color("#2E2E2E")
    textAlign = TextAlign.left
}
