package pages.constructor.ui.components.elements

import csstype.*
import utils.types.CssStyle

val tabsContainer: CssStyle = {
    display = Display.grid
    justifyContent = JustifyContent.stretch
    alignItems = AlignItems.stretch
    gridTemplateColumns = repeat(4, 1.fr)
    justifyItems = JustifyItems.center
}

val tabText: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(500)
    fontSize = 14.px
    lineHeight = 17.px

    color = Color("#000")

    padding = Padding(11.px, 0.px)
}

val tab: CssStyle = {
    display = Display.flex
    background = Color("#00000000")
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center

    whiteSpace = WhiteSpace.normal
    width = 68.px
    height = Auto.auto

    borderWidth = 0.px
    borderRadius = 0.px

    borderTopWidth = 0.px
    borderRightWidth = 0.px
    borderLeftWidth = 0.px
    borderBottomWidth = 1.px
    borderStyle = LineStyle.solid
    borderColor = Color("#000")
    borderRadius = 0.px
}

val tabsAndContent: CssStyle = {
    padding = Padding(4.px, 16.px, 16.px, 16.px)
}


// Color To Filter: https://codepen.io/sosuke/pen/Pjoqqp
// #2E2E2E - color filter
val darkBlackFilter: CssStyle = {
    filter = invert(12.pct).also { sepia(1.pct) }.also { saturate(347.pct) }.also { hueRotate(314.deg) }
        .also { brightness(99.pct) }.also { contrast(86.pct) }
}

var blue700Filter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(20.pct), sepia(54.pct), saturate(4332.pct),
        hueRotate(223.deg), brightness(87.pct), contrast(92.pct))
}

val blackFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct))
}

val darkGreyFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(20.pct), sepia(56.pct), saturate(323.pct),
        hueRotate(177.deg), brightness(93.pct), contrast(86.pct))
}

// #2E2E2E - color filter
val grayFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(73.pct), sepia(5.pct), saturate(5.pct), hueRotate(323.deg),
        brightness(91.pct), contrast(96.pct))
}

// #82ca9d - color filter
val greenFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(77.pct), sepia(2.pct), saturate(4618.pct),
        hueRotate(96.deg), brightness(98.pct), contrast(88.pct))
}

// #8884d8 - color filter
val lightBlueFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(61.pct), sepia(32.pct), saturate(3378.pct),
        hueRotate(209.deg), brightness(94.pct), contrast(79.pct))
}

// #597EF7 - color filter
val bgBlueFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(42.pct), sepia(45.pct), saturate(2373.pct),
        hueRotate(211.deg), brightness(101.pct), contrast(94.pct))
}

// #FFFFFF - color filter
val whiteFilter: CssStyle = {
    filter = array(brightness(0), saturate(100.pct), invert(100.pct))
}

// #2F54EB - color filter
val blueFilter: CssStyle = {
    filter = "brightness(0%) saturate(100%) invert(21%) sepia(59%) saturate(4395%) hue-rotate(228deg) brightness(98%) contrast(89%)".unsafeCast<Filter>()
}

val headerContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.row
    alignItems = AlignItems.center

    // Пришлось подгонять падинги, тк. остальные колапсы не совпадают с Figma

    paddingTop = 14.px
    paddingBottom = 14.px
    paddingLeft = 16.px
    paddingRight = 11.px
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    color = Color("#2E2E2E")
    fontSize = 20.px
    flex = Flex.maxContent
}