package pages.payment.ui.Payment

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent
import utils.types.shadowArray

val page: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    flexDirection = FlexDirection.column
    backgroundColor = Color("#F4F7FF")
    minHeight = 120.vh
    fontFamily = string("\"Lexend\", system-ui")
    maxWidth = 100.vw
    boxSizing = BoxSizing.borderBox
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    width = FitContent.fitContent
    gap = 28.px
    padding = 40.px
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
    width = 100.pct
    maxWidth = 605.px
}

val backButton: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    border = None.none
    boxShadow = None.none
    color = Color("#2F54EB")
    padding = 0.px
    width = FitContent.fitContent
    fontWeight = integer(600)
    fontSize = 16.px
    alignSelf = AlignSelf.flexStart
}

val perk: CssStyle = {
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
    gap = 10.px
}

val perks: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.flexStart
    gap = 10.px
    alignSelf = AlignSelf.flexStart
}

val description: CssStyle = {
    margin = 0.px
}

val centeredDescription: CssStyle = {
    margin = 0.px
    alignItems = AlignItems.center
}
