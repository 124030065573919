package pages.googleAuth.ui.emailWithCode

import antd.Button
import antd.Input
import csstype.Auto
import csstype.Color
import csstype.pct
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.js.timers.Timeout
import kotlinx.js.timers.clearInterval
import kotlinx.js.timers.setInterval
import online.interactiver.common.user.LogInWithCodeRequest
import online.interactiver.common.user.SendCodeRequest
import pages.googleAuth.loginWithCodeRequest
import pages.googleAuth.sendCodeRequest
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.useState
import shared.components.description.Description
import utils.types.extend
import widgets.LanguageAutoContent.ui.generateButton
import widgets.LanguageAutoSlider.ui.components.ShareOrCreateNew.newTaskButton
import kotlin.time.Duration.Companion.seconds

external interface EmailWithCodeProps : Props {
    var nativeLanguage: String
}

val EmailWithCode = FC<EmailWithCodeProps> {props ->
    val (email, setEmail) = useState("")
    val (code, setCode) = useState("")
    val (isWrongCode, setIsWrongCode) = useState(false)
    val (codeWasSent, setCodeWasSent) = useState(false)
    val (secondsSendCodeDisabled, setSecondsSendCodeDisabled) = useState<Int>()
    val location = useLocation()
    val navigate = useNavigate()
    val (searchParams) = useSearchParams()
    val fromStudentDuo = searchParams.get("duo")?.toBooleanStrictOrNull()
    val fromDialogs = searchParams.get("dialogs")

    Input {
        type = InputType.email
        placeholder = if (props.nativeLanguage == "Russian") "Введите ваш email-адрес" else "Enter your email"
        value = email
        onInput = { setEmail(it.currentTarget.value) }
    }
    if (codeWasSent) {
        Input {
            if (isWrongCode) {
                css {
                    borderColor = Color("red")
                }
            }
            placeholder = if (props.nativeLanguage == "Russian") "Введите код из почты" else "Enter code from email"
            value = code
            onInput = {
                if (isWrongCode) {
                    setIsWrongCode(false)
                }
                setCode(it.currentTarget.value)
            }
        }
        if (isWrongCode) {
            div {
                css {
                    marginRight = Auto.auto
                }
                Description {
                    span {
                        css {
                            color = Color("red")
                        }
                        +if (props.nativeLanguage == "Russian") "Неверный код" else "Wrong code"
                    }
                }
            }
        }
        Button {
            css(generateButton.extend {
                width = 100.pct
            })
            onClick = {
                GlobalScope.launch {
                    val success = loginWithCodeRequest(LogInWithCodeRequest(code, email))
                    if (!success) {
                        setIsWrongCode(true)
                        return@launch
                    }

                    location.state?.let {
                        localStorage.setItem("previous_page", it.asDynamic().from)
                    }
                    val to = if (fromStudentDuo == true) "/duo" else if (fromDialogs != null) "/dialogs" else ""
                    window.location.href = to
                }
            }
            +if (props.nativeLanguage == "Russian") "Продолжить" else "Continue"
        }
    }
    Button {
        css((if (codeWasSent) newTaskButton else generateButton).extend {
            width = 100.pct
        })
        disabled = secondsSendCodeDisabled != null || email.isBlank()
        onClick = {
            if (!codeWasSent) {
                setCodeWasSent(true)
            }
            setSecondsSendCodeDisabled(30)
            var id: Timeout? = null
            var localSeconds = 30
            val decreaseSeconds = f@{
                console.log(id)
                if (localSeconds == 1) {
                    id?.let {  clearInterval(it) }
                    setSecondsSendCodeDisabled(null)
                    return@f
                }

                localSeconds -= 1
                setSecondsSendCodeDisabled { it?.let { it - 1 } }
            }
            id = setInterval(1.seconds, decreaseSeconds)
            GlobalScope.launch {
                sendCodeRequest(SendCodeRequest(email))
            }
        }

        +if (codeWasSent) {
            if (props.nativeLanguage == "Russian") "Получить новый код на email" else "Send new code to email"
        } else {
            if (props.nativeLanguage == "Russian") "Получить код на email" else "Send code to email"
        }
    }
    if (secondsSendCodeDisabled != null) {
        Description {
            +if (props.nativeLanguage == "Russian") "Доступно через $secondsSendCodeDisabled секунд" else "Available after $secondsSendCodeDisabled seconds"
        }
    }
}
