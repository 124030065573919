package widgets.Exercises.ui.components.UserActions

import csstype.*
import utils.types.CssStyle

val container: CssStyle = {
    width = 164.px
}

val action: CssStyle = {
    height = 32.px
    display = Display.flex
    alignItems = AlignItems.center
    gap = 4.px
    cursor = Cursor.pointer
    hover {
        backgroundColor = Color("#F4F7FF")
    }
}

val header: CssStyle = {
    color = Color("#282F3E")
    fontWeight = FontWeight.bold
    lineHeight = 28.6.px
    fontSize = 22.px
    fontFamily = string("\"Lexend\", system-ui")
    margin = 0.px
}

val iconContainer: CssStyle = {
    minWidth = 32.px
    height = 100.pct
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.center
}

val modalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 22.px
}

val modalTextContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    whiteSpace = WhiteSpace.breakSpaces
}

val modalButtonsContainer: CssStyle = {
    display = Display.flex
    width = 100.pct
    justifyContent = JustifyContent.spaceBetween
}

val deleteText: CssStyle = {
    color = Color("#5D6676")
    fontWeight = integer(400)
    fontSize = 16.px
    lineHeight = 21.6.px
}