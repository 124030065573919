package pages.duoTheme.ui

import csstype.*
import utils.types.CssStyle
import utils.types.FitContent
import utils.types.shadowArray

val page: CssStyle = {
    width = 100.pct
    maxWidth = 414.px
    minHeight = 90.vh
    boxSizing = BoxSizing.borderBox
    backgroundColor = Color("white")
    borderRadius = 12.px
    boxShadow = shadowArray(
        BoxShadow(0.px, 3.px, 18.px, (-1).px, Color("#32324717")), BoxShadow(0.px, 0.px, 1.px, 0.px, Color("#0C1A4B3D"))
    )
}

val container: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 20.px
    padding = 20.px
}

val buttonsContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    alignItems = AlignItems.center
    gap = 10.px
    width = 100.pct
}

val label: CssStyle = {
    color = Color("#282F3E")
    fontWeight = integer(600)
    fontSize = 15.px
    fontStyle = FontStyle.normal
    fontFamily = string("\"Lexend\", system-ui")
}

val topicsCss: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
}

val topicButton: CssStyle = {
    padding = 10.px
    borderRadius = 12.px
    border = None.none
    maxWidth
    width = 90.pct
    height = FitContent.fitContent
    fontWeight = integer(600)
    boxShadow = shadowArray(
        BoxShadow(0.px, 0.px, 0.5.px, 0.px, rgba(12, 26, 75, 0.24)),
        BoxShadow(0.px, 3.px, 14.px, (-1).px, rgba(50, 50, 71, 0.09))
    )
    whiteSpace = WhiteSpace.normal
}

val textAreaContainer: CssStyle = {
    width = 100.pct
}
