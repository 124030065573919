package shared.components.selectWithLabel

import csstype.*
import utils.types.CssStyle

val verticalContainer: CssStyle = {
    display = Display.flex
    flexDirection = FlexDirection.column
    gap = 10.px
    width = 100.pct
    maxWidth = 256.px
}

val label: CssStyle = {
    margin = 0.px
    color = Color("#282F3E")
    fontWeight = integer(600)
    lineHeight = 18.9.px
    fontSize = 15.px
    fontFamily = string("\"Lexend\", system-ui")
}
