package pages.constructor.ui.components.editors.designEditor

import csstype.*
import utils.types.CssStyle

val headerContainer: CssStyle = {
    padding = 20.px
}

val themesContainer: CssStyle = {
    padding = Padding(0.px, 20.px, 0.px)
    marginTop = 20.px
    borderTop = Border(0.5.px, LineStyle.solid, Color("#D0D5DD"))
}

val header: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(700)
    fontSize = 12.px
    lineHeight = 18.px
    letterSpacing = (-0.002).em
    color = Color("#2E2E2E")
    flex = None.none
    order = integer(0)
    flexGrow = number(0.0)
}

val deviceContainer: CssStyle = {
    display = Display.flex
    justifyContent = JustifyContent.spaceBetween
    paddingLeft = 20.px
    paddingRight = 20.px
    paddingBottom = 20.px
}

val deviceButton: CssStyle = {
    width = 96.px
    height = 36.px
    background = Color("#F9FAFC")
    borderRadius = 5.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceAround
    border = None.none
    color = Color("#282F3E")
}

val activeDeviceButton: CssStyle = {
    width = 96.px
    height = 36.px
    background = Color("#F4F7FF")
    borderRadius = 5.px
    display = Display.flex
    alignItems = AlignItems.center
    justifyContent = JustifyContent.spaceAround
    border = None.none
    color = Color("#2F54EB")
}

val deviceText: CssStyle = {
    fontFamily = string("\"Lexend\", system-ui")
    fontStyle = FontStyle.normal
    fontWeight = integer(400)
    fontSize = 10.px
    lineHeight = 130.pct
}

val activeFilter: CssStyle = {
    color = important(Color("#FFFFFF"))
    filter = ("invert(52%) sepia(29%) saturate(5446%) " +
            "hue-rotate(207deg) brightness(96%) contrast(102%)").unsafeCast<FilterFunction>()
    backgroundColor = important(rgba(0, 0, 0, 0.0))
    transitionDuration = 0.s
}

